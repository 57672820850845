export class InvalidTargetError extends Error {
  constructor(message) {
    super(message);
    this.name = 'InvalidTargetError';
  }
}

export class ElementNotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ElementNotFoundError';
  }
}
