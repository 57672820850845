const keyboardFocusOnly = () => {
  function addKeyboardFocus(e) {
    if (e.keyCode !== 9) return;
    document.documentElement.classList.add('keyboard-focus');
  }

  function removeKeyboardFocus() {
    if (!document.documentElement.classList.contains('keyboard-focus')) return;

    document.documentElement.classList.remove('keyboard-focus');
  }

  document.addEventListener('keydown', addKeyboardFocus, false);
  document.addEventListener('mousedown', removeKeyboardFocus, false);
};
export default keyboardFocusOnly;
