import keyboardFocusOnly from '@js/modules/keyboard-focus';
import { gsap } from '@gsap/shockingly';
import { ScrollTrigger } from '@gsap/shockingly/ScrollTrigger';
import { Draggable } from '@gsap/shockingly/Draggable';
import { ScrollSmoother } from '@gsap/shockingly/ScrollSmoother';
import { InertiaPlugin } from '@gsap/shockingly/InertiaPlugin';
import { Flip } from '@gsap/shockingly/Flip';

gsap.registerPlugin(
  ScrollTrigger,
  Flip,
  Draggable,
  ScrollSmoother,
  InertiaPlugin
);

const files = require.context(
  'components',
  true,
  /^(?!.*(config|test|mixin|composable)).*\.(js)$/
);
files.keys().forEach(files);

keyboardFocusOnly();
