import { ref, watchEffect, toRefs, readonly, unref } from 'vue';

const LOWEST_PAGE_LIMIT = 1;

export function usePageLimitByResponse(page, lastResponse, options) {
  const DEFAULT_OPTIONS = Object.freeze({
    firstPage: LOWEST_PAGE_LIMIT,
    paramKey: 'lastPage',
  });

  const { firstPage, paramKey } = toRefs({ ...DEFAULT_OPTIONS, ...options });

  const reachedLastPageOnce = ref(false);
  const lastPage = ref(Infinity);
  watchEffect(() => {
    if (lastResponse.value?.[unref(paramKey)]) {
      reachedLastPageOnce.value = true;
      lastPage.value = page.value;
    }
  });

  const reachedFirstPageOnce = ref(false);
  watchEffect(
    () => {
      if (page.value === firstPage.value) {
        reachedFirstPageOnce.value = true;
      }
    },
    { immediate: true }
  );

  const reset = () => {
    reachedLastPageOnce.value = false;
    reachedFirstPageOnce.value = false;
    lastPage.value = Infinity;
  };

  return {
    firstPage: readonly(firstPage),
    reachedFirstPageOnce: readonly(reachedFirstPageOnce),
    lastPage: readonly(lastPage),
    reachedLastPageOnce: readonly(reachedLastPageOnce),
    reset,
  };
}

export function usePageLimitByPageList(page, pageList) {
  const firstPage = ref(pageList.value[0]);
  const reachedFirstPageOnce = ref(false);
  watchEffect(
    () => {
      if (page.value === firstPage.value) {
        reachedFirstPageOnce.value = true;
      }
    },
    { immediate: true }
  );

  const lastPage = ref(pageList.value[pageList.value.length - 1]);
  const reachedLastPageOnce = ref(false);
  watchEffect(
    () => {
      if (page.value === lastPage.value) {
        reachedLastPageOnce.value = true;
      }
    },
    { immediate: true }
  );

  const reset = () => {
    reachedLastPageOnce.value = false;
    reachedFirstPageOnce.value = false;
  };

  return {
    firstPage: readonly(firstPage),
    reachedFirstPageOnce: readonly(reachedFirstPageOnce),
    lastPage: readonly(lastPage),
    reachedLastPageOnce: readonly(reachedLastPageOnce),
    reset,
  };
}
