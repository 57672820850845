const state = {
  activeBreakpointsList: {
    xs: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  },
  innerWidth: 0,
  innerHeight: 0,
  scrollX: 0,
  scrollY: 0,
};

const mutations = {
  updateHeight(state, innerHeight) {
    state.innerHeight = innerHeight;
  },
  updateWidth(state, innerWidth) {
    state.innerWidth = innerWidth;
  },
  updateScroll(state, { scrollX, scrollY }) {
    state.scrollX = scrollX;
    state.scrollY = scrollY;
  },
  updateActiveBreakpointsList(state, { activeBreakpointsList }) {
    state.activeBreakpointsList = activeBreakpointsList;
  },
};

const getters = {
  scrollY: state => {
    return state.scrollY;
  },
  scrollX: state => {
    return state.scrollX;
  },
  innerWidth: state => {
    return state.innerWidth;
  },
  innerHeight: state => {
    return state.innerHeight;
  },
  breakpointKeys: state => {
    return Object.keys(state.activeBreakpointsList);
  },
  breakpoint: (state, getters) => {
    const length = getters.breakpointKeys.length;
    const firstFalseIndex = Object.entries(
      state.activeBreakpointsList
    ).findIndex(([key, value]) => !value);

    return getters.breakpointKeys[
      (firstFalseIndex === -1 ? length : firstFalseIndex) - 1
    ];
  },
  breakpointUp: (state, getters) => breakpoint => {
    const testIndex = getters.breakpointKeys.indexOf(breakpoint);
    const currentIndex = getters.breakpointKeys.indexOf(getters.breakpoint);

    return currentIndex >= testIndex;
  },
  breakpointDown: (state, getters) => breakpoint => {
    const testIndex = getters.breakpointKeys.indexOf(breakpoint);
    const currentIndex = getters.breakpointKeys.indexOf(getters.breakpoint);

    return currentIndex < testIndex;
  },
  breakpointOnly: (state, getters) => breakpoint => {
    const testIndex = getters.breakpointKeys.indexOf(breakpoint);
    const currentIndex = getters.breakpointKeys.indexOf(getters.breakpoint);

    return currentIndex === testIndex;
  },
  /*
    accepts configurations like
    {
      something: false,
      items: 1,
      breakpoints: {
        sm: { items: 2 },
        xl: { items: 3 },
      }
    }

    example output, for sm:
    { something: false, items: 2}

    example output, for md:
    { something: false, items: 2}

    example output, for xl:
    { something: false, items: 3}
  */
  mergeConfigByBreakpoint: (state, getters) => configInitial => {
    let config = configInitial;

    if (!configInitial.breakpoints) {
      return { lastValidBreakpoint: undefined, config };
    }

    let lastValidBreakpoint = undefined;

    getters.breakpointKeys.forEach(breakpoint => {
      const configBreakpoint = configInitial.breakpoints[breakpoint];
      if (configBreakpoint && getters.breakpointUp(breakpoint)) {
        lastValidBreakpoint = breakpoint;
        config = { ...config, ...configBreakpoint };
      }
    });

    delete config.breakpoints;

    return { lastValidBreakpoint, config };
  },
};

const actions = {
  setActiveBreakpointList({ commit }, { activeBreakpointsList }) {
    commit('updateActiveBreakpointsList', {
      activeBreakpointsList: JSON.parse(JSON.stringify(activeBreakpointsList)),
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
