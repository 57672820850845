import { getHtmlLanguage } from '@utils/various';

import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/de';

dayjs.extend(duration);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Europe/Berlin');

dayjs.updateLocale('de', {
  monthsShort: [
    'Jan',
    'Feb',
    'Mrz',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
});

// TODO: logic duplicated inside '@stores/labels'
const htmlLanguage = getHtmlLanguage() || 'en';
dayjs.locale(htmlLanguage === 'de' ? 'de' : 'en');
