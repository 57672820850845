import breakpoints from '@scss/breakpoints.module.scss';

const handlers = {
  // TODO: maybe use matchMedia instead?
  resize: {
    immediate: true,
    passive: true,

    callback: store => {
      return event => {
        const width = window.innerWidth;
        store.commit('window/updateWidth', width);
        const height = window.innerHeight;
        store.commit('window/updateHeight', height);
      };
    },
  },

  scroll: {
    immediate: true,
    passive: true,

    callback: store => {
      return event => {
        const scroll = {
          scrollX: window.scrollX,
          scrollY: window.scrollY,
        };
        store.commit('window/updateScroll', scroll);
      };
    },
  },
};

function handleWindowHandlers(store) {
  for (const type in handlers) {
    const handler = handlers[type];

    const callback = (
      typeof handler === 'function' ? handler : handler.callback
    )(store);

    const config = {
      ...(handler.passive ? { passive: true } : {}),
    };

    window.addEventListener(type, callback, config);

    if (handler.immediate) {
      setTimeout(callback, 0);
    }
  }
}

function handleBreakpointHandler(store) {
  function compare(objA, objB) {
    let comp = 0;
    if (objA > objB) {
      comp = 1;
    } else if (objA < objB) {
      comp = -1;
    }
    return comp;
  }

  const sortedBreakpointsEntries = Object.entries(breakpoints).sort(
    ([keyA, valueA], [keyB, valueB]) => compare(valueA, valueB)
  );

  const activeBreakpointsList = Object.fromEntries(
    sortedBreakpointsEntries.map(([key, minWidth]) => [key, false])
  );

  const setActiveBreakpointsList = () => {
    store.dispatch('window/setActiveBreakpointList', {
      activeBreakpointsList,
    });
  };

  sortedBreakpointsEntries.forEach(([key, minWidth]) => {
    const mediaQueryList = window.matchMedia(`(min-width: ${minWidth})`);

    activeBreakpointsList[key] = mediaQueryList.matches;

    // const addListener = mediaQueryList.addEventListener
    //   ? mediaQueryList.addEventListener.bind(this, 'change')
    //   : mediaQueryList.addListener.bind(this);

    mediaQueryList.addListener(event => {
      activeBreakpointsList[key] = event.matches;
      setActiveBreakpointsList();
    });
  });

  setActiveBreakpointsList();
}

export default store => {
  handleWindowHandlers(store);
  handleBreakpointHandler(store);
};
