import { createApp, defineAsyncComponent, configureCompat } from 'vue';
import UUID from 'vue-uuid';
import PortalVue from 'portal-vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { PiniaSharedState } from 'pinia-shared-state';
import { DpcPicture } from 'dpc-ui';
import TextClamp from 'vue3-text-clamp';

import store from './store';
import './directives';

configureCompat({ ATTR_FALSE_VALUE: false, TRANSITION_GROUP_ROOT: false });

function getNameByPath(path) {
  if (!path) return;

  const name = path
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');
  const nameFormatted = upperFirst(camelCase(name));

  return nameFormatted;
}

const app = createApp({});

const pinia = createPinia();
pinia.use(PiniaSharedState({ enable: false }));
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

app.use(store);

const importedFiles = [];

import importGroupUtilities from './import-groups/utilities';
import importGroupAboveFold from './import-groups/above-fold';

const importGroupsComponents = [
  ...importGroupUtilities,
  ...importGroupAboveFold,
];

importGroupsComponents.forEach(component => {
  const componentName = getNameByPath(component.__file);

  if (importedFiles.includes(componentName)) return;
  importedFiles.push(componentName);

  app.component(componentName, component);
});

app.component('DpcPicture', DpcPicture);
app.component('TextClamp', TextClamp);

// import(
//   /* webpackChunkName: "scrollers", webpackMode: "lazy" */
//   './import-groups/scrollers'
// ).then(({ default: components }) => {
//   components.forEach(component => {
//     app.component(getNameByPath(component.__file), component);
//   });
// });

const ComponentContext = require.context(
  '@components',
  true,
  /\.vue$/i,
  'lazy'
);

ComponentContext.keys().forEach(filePath => {
  const name = getNameByPath(filePath);
  app.component(
    name,
    defineAsyncComponent({ loader: () => ComponentContext(filePath) })
  );
});

app.use(UUID);
app.use(PortalVue);

app.config.compilerOptions.whitespace = 'condense';

app.mount('#app');
/* eslint-disable-next-line */
window.___VM___ = app;
