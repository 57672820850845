// import UEventListener from '../../../components/_utilities/u-event-listener.vue';
// import UIntersect from '../../../components/_utilities/u-intersect.vue';
// import UTransitionHeight from '../../../components/_utilities/u-transition-height.vue';
// import AFocusTrap from '../../../components/_accessibility/a-focus-trap.vue';
// import ALabel from '../../../components/_accessibility/a-label.vue';

// // eslint-disable-next-line
//
//   UEventListener,
//   UIntersect,
//   UTransitionHeight,
//   AFocusTrap,
//   ALabel,
// ];
export default [];
