import Vue from 'vue';

/* STORE MODULE */

const state = {
  instances: {},
};

const mutations = {
  newInstance({ instances }, { instanceKey, instance }) {
    instances[instanceKey] = instance;
  },
  setOpen({ instances }, { instanceKey, isOpen }) {
    instances[instanceKey]['isOpen'] = isOpen;
  },
};

const getters = {
  hasInstance:
    ({ instances }) =>
    ({ instanceKey }) => {
      return !!instances[instanceKey];
    },
  getInstance:
    ({ instances }) =>
    ({ instanceKey }) => {
      const instance = instances[instanceKey] || false;
      return instance;
    },
  isOpen:
    ({ instances }) =>
    ({ instanceKey }) => {
      const instance = instances[instanceKey];
      return instance?.isOpen;
    },
};

const actions = {
  newInstance({ commit }, { instanceKey }) {
    const instance = {
      isOpen: false,
    };

    commit('newInstance', { instanceKey, instance });
  },
  open({ commit }, { instanceKey }) {
    commit('setOpen', { instanceKey, isOpen: true });
  },
  open({ commit }, { instanceKey }) {
    commit('setOpen', { instanceKey, isOpen: true });
  },
  close({ commit }, { instanceKey }) {
    commit('setOpen', { instanceKey, isOpen: false });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
