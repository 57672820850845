import Vue from 'vue';
import { FocusRedirect } from '../modules/focus-redirect';

function getConfigByBreakpoint(configInitial, $store) {
  const mergeConfig = $store.getters['window/mergeConfigByBreakpoint'];
  const config = mergeConfig(configInitial).config;
  return config;
}

const activeHooks = new Map();

Vue.directive('focus-redirect', {
  mounted(el, binding, vnode) {
    if (!binding.value) return;

    const $store = vnode.context.$store;
    const config = getConfigByBreakpoint(binding.value, $store);
    const focusRedirect = new FocusRedirect(el, config);
    activeHooks.set(el, focusRedirect);

    if (config.xl) {
      console.warn(
        el,
        'Definition of breakpoints in v-focus-redirect should be inside the breakpoints object!'
      );
    }

    if (config.isDisabled === true) {
      focusRedirect.deactivate();
    } else {
      focusRedirect.activate();
    }
  },
  updated(el, binding, vnode) {
    if (!binding.value) return;

    const focusRedirect = activeHooks.get(el);
    const $store = vnode.context.$store;
    const config = getConfigByBreakpoint(binding.value, $store);
    focusRedirect.updateConfig(config);

    if (config.isDisabled === true) {
      focusRedirect.deactivate();
    } else {
      focusRedirect.activate();
    }
  },
  unbind(el) {
    const focusRedirect = activeHooks.get(el);
    focusRedirect && focusRedirect.deactivate();
    activeHooks.delete(el);
  },
});
