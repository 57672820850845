import dayjs from 'dayjs';
import { computed } from 'vue';

export function useCalendarMonths({ dateStart, dateEnd, datesWithContent }) {
  const duration = computed(() => {
    const duration = dayjs
      .unix(dateEnd.value)
      .date(28)
      .diff(dayjs.unix(dateStart.value).date(1), 'month', true);
    return Math.max(Math.ceil(Math.abs(duration)), 0);
  });

  const calendarMonths = computed(() => {
    return Array(duration.value)
      .fill(null)
      .map((_, index) => {
        const date = dayjs.unix(dateStart.value).add(index, 'month');
        const dateUnix = String(date.unix());
        return {
          unix: dateUnix,
          yearMonth: date.format('YYYY-MM'),
          id: date.format('YYYY-MM'),
          monthName: date.format('MMMM'),
          hasContent: monthHasContent(dateUnix),
        };
      });
  });

  const monthHasContent = date => {
    return !(
      datesWithContent.value.findIndex(activeDate => {
        return dayjs.unix(date).isSame(dayjs.unix(activeDate), 'month');
      }) === -1
    );
  };

  return { calendarMonths };
}
