/**
 * Tests which event is working for the current browser
 * original by David Walsh: http://davidwalsh.name/css-animation-callback
 *
 * @param {Object[]} eventItems - A list of events
 * @param {string} eventItems[].event - The name of the JS event
 * @param {string} eventItems[].property - The name of the CSS property
 * @returns {string} The event's name
 */
function findValidEvent(events) {
  const el = document.createElement('fakeelement');
  const event = events.find(({ property }) => el.style[property] !== undefined);

  return event.name;
}

/** The animation end event for the current browser */
export const animationEvent = findValidEvent([
  { property: 'animation', name: 'animationend' },
  { property: 'OAnimation', name: 'oAnimationEnd' },
  { property: 'MozAnimation', name: 'animationend' },
  { property: 'WebkitAnimation', name: 'webkitAnimationEnd' },
]);

/** The transition end event for the current browser */
export const transitionEvent = findValidEvent([
  { property: 'transition', name: 'transitionend' },
  { property: 'OTransition', name: 'oTransitionEnd' },
  { property: 'MozTransition', name: 'transitionend' },
  { property: 'WebkitTransition', name: 'webkitTransitionEnd' },
]);

/**
 *  Resolves when an event happens for an element
 *
 * @export
 * @param {HTMLElement} element - The element
 * @param {string} eventName - The name of the event
 * @returns {Promise}
 */
export function untilEvent(element, eventName) {
  return new Promise(resolve => {
    function finished(event) {
      element.removeEventListener(eventName, finished);
      resolve(event);
    }
    element.addEventListener(eventName, finished);
  });
}

/**
 * Resolves after an animation frame
 *
 * @export
 * @returns {Promise}
 */
export function animationFrame() {
  return new Promise(resolve => {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(resolve);
    });
  });
}

export const easing = t => {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
};

export const slideDown = (element, duration, from = 0, callback) => {
  let start;
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    let time = timestamp - start;
    let percent = Math.min(time / duration, 1);
    percent = easing(percent);

    element.style.height =
      from + (element.scrollHeight - from) * percent + 'px';

    if (time < duration) {
      window.requestAnimationFrame(step);
    } else if (callback) {
      callback();
    }
  });
};

export const slideUp = (element, duration, to = 0, callback) => {
  let start;
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    let time = timestamp - start;
    let percent = Math.min(time / duration, 1);
    percent = easing(percent);

    element.style.height =
      element.scrollHeight - (element.scrollHeight - to) * percent + 'px';

    if (time < duration) {
      window.requestAnimationFrame(step);
    } else if (callback) {
      callback();
    }
  });
};
