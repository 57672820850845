/**
 * Resolves when function within `max` executions return something truthful
 * @export
 * @param {*} func Function which will be attempted
 * @param {Object} config Configuration
 * @param {number} config.max Max attempt count
 * @param {number} config.delay Delay between attempts in ms
 * @returns
 */
export function attempt(func, { max, delay }) {
  return new Promise(async (resolve, reject) => {
    /** lets give it `max` chances */
    let attemptCount = 0;
    let result = undefined;

    do {
      if (attemptCount > 0) await timeout(delay);
      attemptCount++;
      result = func({ attemptCount });
    } while (!result && attemptCount < max);

    if (!result) {
      reject({ attemptCount });
    }

    resolve({ attemptCount, result });
  });
}

/**
 * Resolves after time
 *
 * @export
 * @param {number} time
 * @returns {Promise}
 */
export function timeout(time) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

/**
 * Returns <html> lang key
 *
 * @returns {string} language
 */
export function getHtmlLanguage() {
  try {
    if (!!document.documentElement.lang.match(/^[a-z]{2}(-[A-Z]{2})?$/g)) {
      return document.documentElement.lang.split('-')[0];
    }
    throw new Error(
      `No valid locale string in <html> lang attribute. Fallback locale 'de' is used.`
    );
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
