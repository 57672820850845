import Vue from 'vue';
import { animationFrame } from '../../../shared/js/utils/animation';

const smoothScrollTo = e => {
  e.preventDefault();
  scrollHandler(e);
};

const smoothScrollToTop = e => {
  e.preventDefault();
  scrollHandler(e, true, document.body);
};

const scrollHandler = (e, config, customTarget) => {
  const trigger = e.target;
  const targetId = trigger.hash;
  const target = customTarget || document.querySelector(targetId);
  const hasTabIndex = target.attributes.tabindex !== undefined;
  const removeTabIndex = () => {
    target.removeAttribute('tabindex');
    target.removeEventListener('focusout', removeTabIndex);
  };
  const scrollConfig = config || {
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  };

  if (!hasTabIndex) target.setAttribute('tabindex', target.tabIndex);

  trigger.classList.add('is-scrolling');
  target.classList.remove('is-scrolled');
  target.classList.add('is-scrolling');

  animationFrame().then(() => {
    trigger.classList.remove('is-scrolling');
    target.classList.remove('is-scrolling');
    target.focus();
    target.classList.add('is-scrolled');
    if (!hasTabIndex) target.addEventListener('focusout', removeTabIndex);
  });

  target.scrollIntoView(scrollConfig);

  if (targetId) history.pushState(null, null, `${targetId}`);
};

Vue.directive('smooth-scroll', {
  bind(el, binding) {
    if (binding.arg === 'top') {
      el.addEventListener('click', smoothScrollToTop);
      return;
    }
    el.addEventListener('click', smoothScrollTo);
  },
  unbind(el, binding) {
    if (binding.arg === 'top') {
      el.removeEventListener('click', smoothScrollToTop);
      return;
    }
    el.removeEventListener('click', smoothScrollTo);
  },
});
