import { computed, reactive, provide, readonly } from 'vue';

const filterList = reactive({});

export function useCalendarFilter() {
  const registerFilter = (filterId, filterFunction, resetFunction) => {
    filterList[filterId] = {
      filter: filterFunction,
      reset: resetFunction,
    };
  };
  const removeFilter = filterId => {
    delete filterList[filterId];
  };
  const clearFilterList = () => {
    Object.keys(filterList).forEach(key => removeFilter(key));
  };
  const resetAllFilters = () => {
    Object.values(filterList).forEach(filter => filter.reset());
  };

  return { registerFilter, removeFilter, clearFilterList, resetAllFilters };
}

export function useCalendarFilterResult(teaserList) {
  const teaserListFiltered = computed(() => {
    const result = teaserList.value.filter((teaser, index) => {
      // every filter has to be true, thats why we invert
      return !Object.values(filterList).find(
        filter => !filter.filter(teaser, index)
      );
    });

    return result;
  });
  provide('calendar/teaserListFiltered', readonly(teaserListFiltered));

  return { teaserListFiltered: readonly(teaserListFiltered) };
}
