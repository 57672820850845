import Vue from 'vue';

const scrollToWindowTop = () => {
  window.scrollTo(0, 0);
};

Vue.directive('focus-scroll-to-top', {
  bind(el) {
    el.addEventListener('focus', scrollToWindowTop);
  },

  unbind(el) {
    el.addEventListener('focus', scrollToWindowTop);
  },
});
