import { InvalidTargetError } from '../utils/errors';

export class FocusRedirect {
  constructor(el, config) {
    this.el = el;
    this.updateConfig(config);
    this.onKeydown = this.onKeydown.bind(this);
    this.isActive = false;
  }
  updateConfig({ next = {}, previous = {} }) {
    this.next = {};
    this.next.selector = next.selector || undefined;
    this.next.isDisabled = next.isDisabled || false;
    this.previous = {};
    this.previous.selector = previous.selector || undefined;
    this.previous.isDisabled = previous.isDisabled || false;
  }
  activate() {
    if (this.isActive) return;
    this.isActive = true;
    this.el.addEventListener('keydown', this.onKeydown);
  }
  deactivate() {
    if (!this.isActive) return;
    this.isActive = false;
    this.el.removeEventListener('keydown', this.onKeydown);
  }
  getDirection(event) {
    const direction = event.shiftKey ? 'previous' : 'next';
    return direction;
  }
  onKeydown(event) {
    if (event.keyCode !== 9) return; // 9 === Tab

    const direction = this.getDirection(event);
    const target = this[direction];

    if (!target || target.isDisabled) return;

    this.redirect(target.selector)
      .then(() => event.preventDefault())
      .catch(error => {
        if (error.name === 'InvalidTargetError') return;
        throw error;
      });
  }
  redirect(selector) {
    return new Promise((resolve, reject) => {
      const el = document.querySelector(selector);
      if (!el || el.offsetParent === null)
        return reject(new InvalidTargetError());

      el.focus();
      resolve();
    });
  }
}
