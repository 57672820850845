/**
 * With webpack, there are multiple ways to include the polyfills:
 *
 * If useBuiltIns: 'usage' is specified in .babelrc:
 * Do not include @babel/polyfill in either webpack.config.js entry array nor source. Note, @babel/polyfill still needs to be installed.
 *
 * If useBuiltIns: 'entry' is specified in .babelrc:
 * Include @babel/polyfill at the top of the entry point to your application via require or import as discussed above.
 *
 * If useBuiltIns key is not specified or it is explicitly set with useBuiltIns: false in your .babelrc:
 * Add @babel/polyfill directly to the entry array in your webpack.config.js.
 *
 * Read more about babel polyfill here: https://babeljs.io/docs/en/babel-polyfill
 */
// require('@babel/polyfill');
const objectFitImages = require('object-fit-images');

// https://github.com/bfred-it/object-fit-images
// automagically transformed by https://github.com/ronik-design/postcss-object-fit-images
objectFitImages();

// focus - focusOptions - preventScroll polyfill
// https://github.com/calvellido/focus-options-polyfill/blob/master/index.js
(function () {
  if (
    typeof window === 'undefined' ||
    typeof document === 'undefined' ||
    typeof HTMLElement === 'undefined'
  ) {
    return;
  }

  var supportsPreventScrollOption = false;
  try {
    var focusElem = document.createElement('div');
    focusElem.addEventListener(
      'focus',
      function (event) {
        event.preventDefault();
        event.stopPropagation();
      },
      true
    );
    focusElem.focus(
      Object.defineProperty({}, 'preventScroll', {
        get: function () {
          // Edge v18 gives a false positive for supporting inputs
          if (
            navigator &&
            typeof navigator.userAgent !== 'undefined' &&
            navigator.userAgent &&
            navigator.userAgent.match(/Edge\/1[7-8]/)
          ) {
            return (supportsPreventScrollOption = false);
          }

          supportsPreventScrollOption = true;
        },
      })
    );
  } catch (e) {}

  if (
    HTMLElement.prototype.nativeFocus === undefined &&
    !supportsPreventScrollOption
  ) {
    HTMLElement.prototype.nativeFocus = HTMLElement.prototype.focus;

    var calcScrollableElements = function (element) {
      var parent = element.parentNode;
      var scrollableElements = [];
      var rootScrollingElement =
        document.scrollingElement || document.documentElement;

      while (parent && parent !== rootScrollingElement) {
        if (
          parent.offsetHeight < parent.scrollHeight ||
          parent.offsetWidth < parent.scrollWidth
        ) {
          scrollableElements.push([
            parent,
            parent.scrollTop,
            parent.scrollLeft,
          ]);
        }
        parent = parent.parentNode;
      }
      parent = rootScrollingElement;
      scrollableElements.push([parent, parent.scrollTop, parent.scrollLeft]);

      return scrollableElements;
    };

    var restoreScrollPosition = function (scrollableElements) {
      for (var i = 0; i < scrollableElements.length; i++) {
        scrollableElements[i][0].scrollTop = scrollableElements[i][1];
        scrollableElements[i][0].scrollLeft = scrollableElements[i][2];
      }
      scrollableElements = [];
    };

    var patchedFocus = function (args) {
      if (args && args.preventScroll) {
        var evScrollableElements = calcScrollableElements(this);
        if (typeof setTimeout === 'function') {
          var thisElem = this;
          setTimeout(function () {
            thisElem.nativeFocus();
            restoreScrollPosition(evScrollableElements);
          }, 0);
        } else {
          this.nativeFocus();
          restoreScrollPosition(evScrollableElements);
        }
      } else {
        this.nativeFocus();
      }
    };

    HTMLElement.prototype.focus = patchedFocus;
  }
})();

import 'core-js/modules/es.promise';
import 'core-js/modules/es.promise.any';
