const state = {
  isIE: false,
};

const mutations = {
  updateIsIE(state, isIE) {
    state.isIE = innerWidth;
  },
};

const getters = {
  isIE: state => {
    return state.isIE;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
